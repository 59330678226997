import { Injectable, Signal, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';
import { AuthState } from '@stores-states/authentication.state';
import type { WorkOrder, WorkOrderLine } from '@tag/graphql';
import { AccessLevelKey } from '@api/types';

type WoParams = WorkOrder | null | Signal<WorkOrder | null>;
type WolParams = WorkOrderLine | null | Signal<WorkOrderLine | null>;

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private readonly store = inject(Store);

  readonly permissions = toSignal(this.store.select(AuthState.permissions));
  readonly userInfo = toSignal(this.store.select(AuthState.userInfo));

  // ------------------------------ Work Orders ------------------------------

  canEditWorkOrder(workOrder: WoParams) {
    return computed(() => {
      const permission = this.permissions()?.woAccessLevel.update;
      if (!permission || !workOrder) return true;

      const wo = typeof workOrder === 'function' ? workOrder() : workOrder;
      if (!wo) return true;

      return this.checkWorkOrderPermission(permission, wo);
    });
  }

  canDeleteWorkOrder(workOrder: WoParams) {
    return computed(() => {
      const permission = this.permissions()?.woAccessLevel.delete;
      if (!permission) return true;

      const wo = typeof workOrder === 'function' ? workOrder() : workOrder;
      if (!wo) return true;

      return this.checkWorkOrderPermission(permission, wo);
    });
  }

  readonly canCreateWorkOrder = computed(() => {
    const permission = this.permissions()?.woAccessLevel.create;
    if (!permission) return true;

    return permission !== 'None';
  });

  private checkWorkOrderPermission(
    permission: AccessLevelKey,
    workOrder: WorkOrder | WorkOrderLine
  ) {
    const userInfo = this.userInfo();
    if (!userInfo) return true;

    const { technicianId, personnelGroup } = userInfo;

    switch (permission) {
      case 'Full Access':
        return true;
      case 'None':
        return false;
      case 'Technician Access':
        return (
          technicianId === workOrder.technicianCode || !workOrder.technicianCode
        );
      case 'Personnel Group Access':
        return (
          personnelGroup?.includes(workOrder.personnelGroup || '') ||
          !workOrder.personnelGroup ||
          !personnelGroup ||
          personnelGroup.length === 0
        );
      default:
        return true;
    }
  }

  // ------------------------------ Work Order Line ------------------------------

  canEditWorkOrderLine(workOrderLine: WolParams) {
    return computed(() => {
      const permission = this.permissions()?.woLineAccessLevel.update;
      if (!permission) return true;

      const wol =
        typeof workOrderLine === 'function' ? workOrderLine() : workOrderLine;
      if (!wol) return true;

      return this.checkWorkOrderPermission(permission, wol);
    });
  }

  canDeleteWorkOrderLine(workOrderLine: WolParams) {
    return computed(() => {
      const permission = this.permissions()?.woLineAccessLevel.delete;
      if (!permission) return true;

      const wol =
        typeof workOrderLine === 'function' ? workOrderLine() : workOrderLine;
      if (!wol) return true;

      return this.checkWorkOrderPermission(permission, wol);
    });
  }

  readonly canCreateWorkOrderLine = computed(() => {
    const permission = this.permissions()?.woLineAccessLevel.create;
    if (!permission) return true;

    return permission !== 'None';
  });

  readonly workOrderLinePermissions = computed(() => {
    const permission = this.permissions()?.woLineAccessLevel;
    if (!permission) return 'Full Access';

    return permission;
  });

  // ------------------------------ Purchase Order ------------------------------

  readonly canEditPurchaseOrder = computed(() => {
    const permission = this.permissions()?.allowEditPurchase;
    if (!permission) return true;

    return permission;
  });

  readonly canCreatePurchaseOrder = computed(() => {
    const permission = this.permissions()?.allowCreatePurchase;
    if (!permission) return true;

    return permission;
  });
}
